import React from 'react'

function LoginComponent() {
    return (
        <h1>
            Login Component
        </h1>
    )
}

export default LoginComponent;