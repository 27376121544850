import React from 'react'

function AboutComponent() {
    return (
        <h1>
            About Component
        </h1>
    )
}

export default AboutComponent;